<template>
    <div class="citation_box">
        <div class="box">
            <div class="box_part">
                <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick"
                    v-bind:style="{ minHeight: Height - 40 + 'px' }">
                    <el-tab-pane name="zh">
                        <span slot="label"><i class="el-icon-collection-tag"></i>中文文献任务列表</span>
                        <div class="box_list">
                            <div class="operate">
                                <div class="select_css">
                                    <span>任务筛选：</span>
                                    <el-select v-model="value" placeholder="请选择" style="width: 135px; margin: 0px 20px"
                                        @change="selectSort">
                                        <el-option v-for="item in options" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div>
                                    <el-button type="primary" icon="el-icon-delete" @click="deletes">
                                        删除</el-button>

                                    <el-button v-if="value !== 'not'" type="primary" icon="el-icon-plus"
                                        @click="add">添加</el-button>
                                </div>
                            </div>
                            <el-table ref="multipleTable" :data="taskDta" tooltip-effect="dark"
                                style="border-radius: 16px" @selection-change="handleSelectionChange"
                                v-if="value !== 'not'">
                                <el-table-column type="selection" width="40" align="center">
                                </el-table-column>

                                <el-table-column prop="article_title" label="标题" align="left">
                                </el-table-column>
                                <el-table-column prop="article_year" label="发表时间" align="left" width="100">
                                </el-table-column>
                                <el-table-column label="作者" align="center">
                                    <template slot-scope="scope">
                                        <span v-for="(item_article, index) in scope.row.article_author" :key="index">{{
                    item_article }}&ensp;;&ensp;</span>
                                    </template>
                                </el-table-column>

                                <el-table-column prop="article_source_data" label="来源" align="center">
                                </el-table-column>
                                <el-table-column prop="doi" label="DOI" align="center">
                                    <!-- 10.16137/j.cnki.cn12-1303/g4.2018.01.021 -->
                                </el-table-column>
                                <el-table-column prop="issn" label="ISSN" align="center">
                                    <!-- 1671-2277 -->
                                </el-table-column>
                                <el-table-column prop="sub" label="专辑" align="center">
                                    <!-- 社会科学Ⅱ辑; 哲学与人文科学 -->
                                </el-table-column>

                                <el-table-column label="被引频次" align="center" width="100">
                                    <template slot-scope="scope" class="stats">
                                        <el-tag type="success">{{
                    scope.row.article_cited_frequency
                }}</el-tag>
                                    </template>
                                </el-table-column>

                                <template slot="empty">
                                    <div class="imgs">
                                        <img src="../../assets/imgs/zanwushuj.png" alt="" style="width: 50%" />
                                    </div>
                                </template>
                            </el-table>
                            <el-table ref="multipleTable" :data="taskDta01" tooltip-effect="dark"
                                style="border-radius: 16px" v-if="value == 'not'"
                                @selection-change="handleSelectionChange">
                                <el-table-column type="selection" width="40" align="center">
                                </el-table-column>

                                <el-table-column prop="mes" label="导入数据" align="left">
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane name="en">
                        <span slot="label"><i class="el-icon-star-off"></i> 英文文献任务列表</span>
                        <div class="box_list">
                            <div class="operate">
                                <div class="select_css">
                                    <span>任务筛选：</span>
                                    <el-select v-model="value" placeholder="请选择" style="width: 135px; margin: 0px 20px"
                                        @change="selectSort">
                                        <el-option v-for="item in options" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div>
                                    <el-button type="primary" icon="el-icon-delete" @click="deletes">
                                        删除</el-button>

                                    <el-button v-if="value !== 'not'" type="primary" icon="el-icon-plus"
                                        @click="add">添加</el-button>
                                </div>
                            </div>
                            <el-table ref="multipleTable" v-if="value !== 'not'" :data="taskDta" tooltip-effect="dark"
                                style="border-radius: 16px" @selection-change="handleSelectionChange">
                                <el-table-column type="selection" width="40" align="center">
                                </el-table-column>

                                <el-table-column prop="article_title" label="标题" align="left">
                                </el-table-column>
                                <el-table-column prop="article_year" label="发表时间" align="left" width="100">
                                </el-table-column>
                                <el-table-column label="作者" align="center">
                                    <template slot-scope="scope">
                                        <span v-for="(item_article, index) in scope.row.article_author" :key="index">{{
                    item_article }}&ensp;;&ensp;</span>
                                    </template>
                                </el-table-column>

                                <el-table-column prop="article_source_data" label="来源" align="center">
                                </el-table-column>
                                <el-table-column prop="doi" label="DOI" align="center">
                                    <!-- 10.16137/j.cnki.cn12-1303/g4.2018.01.021 -->
                                </el-table-column>
                                <el-table-column prop="issn" label="ISSN" align="center">
                                    <!-- 1671-2277 -->
                                </el-table-column>
                                <el-table-column prop="sub" label="专辑" align="center">
                                    <!-- 社会科学Ⅱ辑; 哲学与人文科学 -->
                                </el-table-column>

                                <el-table-column label="被引频次" align="center" width="100">
                                    <template slot-scope="scope" class="stats">
                                        <el-tag type="success">{{
                    scope.row.article_cited_frequency
                }}</el-tag>
                                    </template>
                                </el-table-column>

                                <template slot="empty">
                                    <div class="imgs">
                                        <img src="../../assets/imgs/zanwushuj.png" alt="" style="width: 50%" />
                                    </div>
                                </template>
                            </el-table>
                            <el-table ref="multipleTable" :data="taskDta01" tooltip-effect="dark"
                                style="border-radius: 16px" v-if="value == 'not'"
                                @selection-change="handleSelectionChange">
                                <el-table-column type="selection" width="40" align="center">
                                </el-table-column>

                                <el-table-column prop="mes" label="导入数据" align="left">
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <el-dialog title="文献导入" :visible.sync="dialogFormVisible" :width="'500px'">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" name="file" :data="ruleForm" label-width="100px"
                class="demo-ruleForm" id="uploadForm" style="margin: 0 50px">
                <el-upload class="upload-demo" id="uploadForm" ref="upload" drag :action="actionUrl"
                    :headers="{ token: token }" :before-upload="beforeUpload" :on-preview="handlePreview"
                    :on-remove="handleRemove" :on-change="on_change" :before-remove="beforeRemove" multiple :limit="1"
                    :on-exceed="handleExceed" :auto-upload="false" :file-list="fileList" style="max-height: 180px">
                    <div class="upStyle">
                        <div>
                            <div style="">
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">
                                    <em style="">点击上传</em>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="el-upload__tip" slot="tip">只能上传txt/docx/pdf文件</div> -->
                </el-upload>
                <div class="note_fu">
                    <div class="note_fu01">
                        <el-divider content-position="left">注意事项</el-divider>
                        <div class="note">
                            <p>
                                <img src="../../assets/imgs/yellow.png" alt="" />
                                <span>所上传中文文献必须为收录在知网的文献</span>
                            </p>
                            <p>
                                <img src="../../assets/imgs/yellow.png" alt="" />
                                <span>文档上传仅限于支持txt格式文件</span>
                            </p>
                            <p>
                                <img src="../../assets/imgs/yellow.png" alt="" />
                                <span>txt文档内数据必须为知网链接，如果想要上传多篇文献，以换行符分隔</span>
                            </p>
                            <p>
                                <img src="../../assets/imgs/yellow.png" alt="" />
                                <span>知网链接推荐域名为https://kns.cnki.net/，如果是其他域名，文献信息可能有所缺失</span>
                            </p>
                            <p>
                                <img src="../../assets/imgs/yellow.png" alt="" />
                                <span>每次仅限上传一个txt文档</span>
                            </p>
                        </div>
                    </div>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消 上 传</el-button>
                <el-button type="primary" @click="submitForm()">确 定 上 传</el-button>
            </div>
        </el-dialog>
        <el-dialog title="添加文献" :visible.sync="centerDialogVisible" :width="'500px'" center>
            <p class="inquire">确认将文献添加到发表文献列表？</p>

            <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="uploadAdd">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import $ from "jquery";
import Vue from "vue";
import { Message } from "element-ui";
import {
    confirmUser,
    getUser,
    judge_user,
    user_mes,
    user_article,
    delete_article,
    new_article,
    new_cited,
    article_export,
    article_import,
    auth_task,
    auth_task_post,
    retracker,
    article_detail,
    article_update,
    retracker_get,
    moreview
} from "@/Api/unquote";
export default {
    data() {
        return {
            Height: 0,
            activeName: "zh",
            taskDta: [],
            value: "done",
            options: [
                {
                    value: "done",
                    label: "已完成任务",
                },
                {
                    value: "not",
                    label: "未完成任务",
                },
                {
                    value: "anew",
                    label: "重新追踪任务",
                },
            ],
            currentPage: 1, // 当前页码
            totalCount: null, //总条数
            actionUrl: "https://track.newacademic.net/api/article_import",
            token: localStorage.getItem("token"),
            ruleForm: {
                title: "",
                author: "",
                task_type: null,
                file: "",
            },
            rules: {
                // title: [{ required: true, message: "请输入文章标题", trigger: "blur" }],
                // author: [
                //   { required: true, message: "请输入文章作者", trigger: "blur" },
                // ],
                // task_type: [
                //   { required: true, message: "请选择检测类型", trigger: "change" },
                // ],
                file: [
                    { required: true, message: "请选择文件上传", trigger: "change" },
                ],
            },
            fileList: [],
            dialogFormVisible: false,
            chosenList: [],
            multipleSelection: [],
            centerDialogVisible: false,
            taskDta01: [],
        };
    },
    mounted() {
        //动态设置内容高度 让footer始终居底   header+footer的高度是190
        // console.log(this.name)
        this.Height = document.documentElement.clientHeight - 140; //监听浏览器窗口变化
        window.onresize = () => {
            this.Height = document.documentElement.clientHeight - 140;
        };
    },
    created() {
        this.taskList();
    },
    methods: {
        taskList() {
            let user_code = {
                user_openid: localStorage.getItem("code"),
            };
            if (this.value == "done" || this.value == "not") {
                auth_task(user_code)
                    .then((res) => {
                        console.log(res);
                        // return;
                        if (this.activeName == "zh") {
                            //   this.taskDta = res.data.zh_data;
                            if (this.value == "done") {
                                this.taskDta = res.data.zh_data.have_data;
                                this.taskDta.forEach((item, index) => {
                                    let id_message = {
                                        ids: item.article_unique,
                                        type: "zh",
                                    };
                                    moreview(id_message)
                                        .then((res) => {
                                            // console.log(res);
                                            Vue.set(item, "doi", res.data.data.doi);
                                            Vue.set(item, "issn", res.data.data.issn);
                                            Vue.set(item, "sub", res.data.data.sub);
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                        });
                                });
                            } else {
                                this.taskDta01 = res.data.zh_data.no_data;
                            }
                        } else {
                            //   this.taskDta = res.data.en_data;
                            if (this.value == "done") {
                                this.taskDta = res.data.en_data.have_data;
                                this.taskDta.forEach((item, index) => {
                                    let id_message = {
                                        ids: item.article_unique,
                                        type: "en",
                                    };
                                    moreview(id_message)
                                        .then((res) => {
                                            // console.log(res);
                                            Vue.set(item, "doi", res.data.data.doi);
                                            Vue.set(item, "issn", res.data.data.issn);
                                            Vue.set(item, "sub", res.data.data.sub);
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                        });
                                });
                            } else {
                                this.taskDta01 = res.data.en_data.no_data;
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                let user_code01 = {
                    user_openid: localStorage.getItem("code"),
                    article_type: "",
                };
                // console.log(this.activeName);
                if (this.activeName == "zh") {
                    user_code01.article_type = "zh";
                } else {
                    user_code01.article_type = "en";
                }
                retracker_get(user_code01)
                    .then((res) => {
                        // console.log(res);
                        this.taskDta = res.data.data;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        handleClick(tab, event) {
            // console.log(tab.index);
            if (tab.index == "0") {
                this.activeName = "zh";
            } else {
                this.activeName = "en";
            }
            this.value = "done";
            this.taskList();
        },
        handleSelectionChange(val) {
            // console.log(val);
            this.multipleSelection = val;
        },
        // 选择类型
        selectSort() {
            // console.log(this.value);
            this.taskList();
        },
        // 删除
        deletes() {
            if (this.multipleSelection.length > 0) {
                let ids = [];
                this.multipleSelection.forEach((item) => {
                    ids.push(item.article_id);
                });
                // console.log(ids);
                if (this.value !== "anew") {
                    let user_ids = {
                        ids: JSON.stringify(ids),
                        user_openid: localStorage.getItem("code"),
                        operation: "del",
                        article_type: this.activeName,
                    };
                    auth_task_post(user_ids)
                        .then((res) => {
                            // console.log(res);
                            if (res.data.code == 200) {
                                this.$message({
                                    message: res.data.message,
                                    type: "success",
                                });
                                this.taskList();
                            }

                            // localStorage.setItem("organ_info", res.data.data);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    let ret_user = {
                        ids: JSON.stringify(ids),
                        user_openid: localStorage.getItem("code"),
                        article_type: "",
                        operation: "del",
                    };
                    if (this.activeName == "zh") {
                        ret_user.article_type = "zh";
                    } else {
                        ret_user.article_type = "en";
                    }
                    // console.log(ret_user.article_type);
                    retracker(ret_user)
                        .then((res) => {
                            console.log(res);
                            if (res.data.code == 200) {
                                this.$message({
                                    message: res.data.message,
                                    type: "success",
                                });
                                this.taskList();
                            }

                            // localStorage.setItem("organ_info", res.data.data);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            } else {
                this.$message({
                    message: "请勾选删除内容！！",
                    type: "warning",
                });
            }
        },
        add() {
            if (this.multipleSelection.length > 0) {
                this.centerDialogVisible = true;
            } else {
                this.$message({
                    message: "请勾选删除内容！！",
                    type: "warning",
                });
            }
        },
        // 添加到任务列表
        uploadAdd() {
            if (this.multipleSelection.length > 0) {
                let ids = [];
                this.multipleSelection.forEach((item) => {
                    ids.push(item.article_id);
                });
                console.log(ids);
                if (this.value !== "anew") {
                    let user_ids = {
                        ids: JSON.stringify(ids),
                        user_openid: localStorage.getItem("code"),
                        operation: "get",
                        article_type: this.activeName,
                    };
                    auth_task_post(user_ids)
                        .then((res) => {
                            console.log(res);
                            if (res.data.code == 200) {
                                this.$message({
                                    message: res.data.message,
                                    type: "success",
                                });
                                this.centerDialogVisible = false;
                                this.taskList();
                            }

                            // localStorage.setItem("organ_info", res.data.data);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    let ret_user = {
                        ids: JSON.stringify(ids),
                        user_openid: localStorage.getItem("code"),
                        article_type: "",
                        operation: "get",
                    };
                    if (this.activeName == "zh") {
                        ret_user.article_type = "zh";
                    } else {
                        ret_user.article_type = "en";
                    }
                    console.log(ret_user.article_type);
                    retracker(ret_user)
                        .then((res) => {
                            console.log(res);
                            if (res.data.code == 200) {
                                this.$message({
                                    message: res.data.message,
                                    type: "success",
                                });
                                this.centerDialogVisible = false;
                                this.taskList();
                            }

                            // localStorage.setItem("organ_info", res.data.data);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            } else {
                this.$message({
                    message: "请勾选删除内容！！",
                    type: "warning",
                });
            }
        },
        // 打开上传
        uploadOpen() {
            this.dialogFormVisible = true;
        },
        handleRemove(file, fileList) {
            // console.log(file, fileList);
        },
        handlePreview(file) {
            // console.log(file);
        },

        // 上传前处理
        beforeUpload(file) {
            //   console.log("aa");
            // let fileSize = file.size;
            // const FIVE_M = 5 * 1024 * 1024;
            // //大于5M，不允许上传
            // if (fileSize > FIVE_M) {
            //   this.$message.error("最大上传5M");
            //   return false;
            // }
            //   判断文件类型，必须是xlsx格式
            //   let fileName = file.name;
            //   let reg = /^.+(\.txt)$/;
            //   if (!reg.test(fileName)) {
            //     this.$message.error("只能上传txt文件!");
            //     return false;
            //   }
            //   return true;
        },
        on_change(file, fileList) {
            console.log(file, fileList);
            let fileName = file.name;
            let reg = /^.+(\.txt)$/;
            if (!reg.test(fileName)) {
                this.$refs.upload.clearFiles();
                this.$message.error("只能上传txt文件!");
            } else {
                this.ruleForm.file = file;
                this.fileList = fileList;
            }
            //   return true;
        },

        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
                } 个文件`
            );
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        reloadProof() {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            });
        },
        // 确认上传
        submitForm() {
            if (this.fileList.length > 0) {
                var formData = new FormData();
                formData.append("my_file", this.fileList[0].raw);
            } else {
                var formData = new FormData($("#uploadForm")[0]);
            }
            formData.append("article_type", "zh");
            formData.append("user_openid", localStorage.getItem("code"));
            // console.log(this.ruleForm);
            // checks(formData)
            //   .then((res) => {
            //     console.log(res);
            //   })
            //   .catch((err) => {
            //     console.log(err);
            //   });
            // var formData = this.ruleForm;
            let that = this;
            $.ajax({
                url: "https://track.newacademic.net/api/article_import",
                type: "POST",
                cache: false,
                data: formData,
                processData: false,
                contentType: false,
                headers: {
                    // "Content-Type": "application/x-www-form-urlencoded",
                    token: localStorage.getItem("token"),
                    // Token:"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NDE5NzQ4OTAsImlhdCI6MTY0MTM3MDA4MCwiZGF0YSI6eyJpZCI6MTY0LCJlbWFpbCI6IjEwMTM3MTU0NzNAcXEuY29tIiwicGhvbmUiOiIxODR4eHh4MDYwNyIsIm5hbWUiOiJcdTVmOTBcdTU2MDlcdTY1NGYiLCJmYWN1bHR5IjoiXHU1ZjAwXHU1M2QxIiwic3RhdHVzIjowfX0.629NEWBqMr2-unocUDSScxUQAhr0G3HgitzdnM4hw7E",
                },
            })
                .done(function (res) {
                    console.log(res);
                    //   return;

                    if (res.code == 200) {
                        Message({
                            message: res.message,
                            type: "success",
                        });
                        that.dialogFormVisible = false;
                        that.taskList();
                    } else {
                        Message({
                            message: res.message,
                            type: "warning",
                        });
                    }

                    // this.returns = false;
                })
                .fail(function (res) {
                    Message.error("提交失败！！请稍后再试！");
                    // this.returns = false;
                });

            return;
            console.log(this.fileList[0].raw);
            //   return
            //   if (this.fileList.length > 0) {
            var formData = new FormData();
            formData.append("my_file", this.fileList[0].raw);
            //   } else {
            //     var formData = new FormData($("#uploadForm")[0]);
            //   }
            console.log(formData);
            formData.append("article_type", "zh");
            formData.append("user_openid", localStorage.getItem("code"));
            let files = {
                article_type: "zh",
                user_openid: localStorage.getItem("code"),
                my_file: this.fileList[0],
            };

            //   formData.append("task_type", 0);

            article_import(files)
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style lang="less" scoped>
.citation_box {
    min-width: 1360px;
    display: flex;

    .box {
        width: 100%;
        .box_part {
            background: white;
            width: 100%;
        }
    }
}

.box_list {
    max-width: 1350px;
    margin: auto;

    .operate {}
}

.pag {
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.operate {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .select_css {
        position: relative;
        left: -20px;

        span {
            color: rgb(0, 0, 0);
            position: relative;
            left: 20px;
        }
    }
}

.note_fu {
    display: flex;
    justify-content: center;
    margin: 20px 0 0 0;

    .note_fu01 {
        width: 100%;
        // max-width: 1070px;
        // margin: 30px auto !important;
    }
}

/deep/ .el-divider {
    // min-width: 800px;
    margin: 0 !important;
    //   width: 90% !important;
}

.note {
    //   width: 90%;
    //    min-width: 800px;
    padding: 10px 10px;
    border-left: 1px #dcdfe6 solid;
    border-right: 1px #dcdfe6 solid;
    border-bottom: 1px #dcdfe6 solid;

    p {
        line-height: 30px;
        // min-width: 1000px;
        text-indent: -1.5em;
        /*em是相对单位，可根据自己实际情况来调节*/
        padding-left: 1.5em;
        /*em是相对单位，可根据自己实际情况来调节*/
        word-wrap: break-word;

        img {
            margin: 0 10px 0 0;
        }
    }
}

/deep/ .el-upload-list {
    margin: 0 20px;
    position: relative;
    top: -50px;
}

.Echarts {
    position: relative;
    top: -50px;
}

.inquire {
    text-align: center;
    font-size: 18px;
    margin: 30px 0 0 0;
}
</style>